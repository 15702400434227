import * as React from 'react';
import { Link } from 'gatsby';

import Container from '../components/Basic/Container';
import Col from '../components/Basic/Col';
import Row from '../components/Basic/Row';
import Footer from '../components/Layout/Footer';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <>
    <Seo title="JukonMedia.pl - Strony nie znaleziono" />
    <Container>
      <Row style={{ padding: '30px 0' }}>
        <Col md={12} style={{ textAlign: 'center' }}>
          <h1 style={{ marginBottom: '30px' }}>Nie ma takiej strony :|</h1>
          <p>Przejdź do <Link to={"/"}>strony głównej</Link></p>
        </Col>
      </Row>
    </Container>
    <Container>
      <Footer />
    </Container>
  </>
);

export default NotFoundPage;
